@media(min-width:992px) {
    #header {
        #logo {
            display: none;
        }  
        &.sticky-header #header-wrap {
            background-color: $gris-oscuro;
            box-shadow: 0 0 10px rgba(255, 255, 255, 0.1);
            #logo {
                display: block;
                margin: 0 auto;
                float: none;
                height: 100px;
                padding-top: 18px;
                img {
                    margin: 0 auto;
                    height: auto;
                    width: 140px;
                }
            }
            
            #primary-menu-trigger {
                height: 20px;
                width: auto;
                line-height: 20px;
                margin-top: -10px;
                z-index: 2;
            }
            .widget_icl_lang_sel_widget {
                top: 50%;
                margin-top: -13px;
            }
        }
        .widget_icl_lang_sel_widget {
            position: absolute;
            right: 15px;
            margin-top: 37px;
        }
    }
    
    .overlay-menu #primary-menu > #overlay-menu-close {
        font-family: $montserrat;
        font-size: 12px;
        letter-spacing: 2px;
        font-weight: normal;
        width: auto;
        right: auto;
        left: 60px;
        i {
            display: inline;
            margin-right: 10px;
            position: relative;
            top: 8px;
            font-size: 30px;
        }
    }
    
    /* FIX OVERLAY */
    .overlay-menu.primary-menu-open {
        overflow-y: hidden;
        #header-wrap {
            position: fixed;
            width: 100%;
            height: 100% !important;
            transition: none !important;
        }

    }

    .overlay-menu #primary-menu > ul.d-block {
        overflow-y: auto;
        background: $texto
    }
}

@media(min-width:1360px){
    .container {
        max-width:1300px;
    }
}

@media(min-width:1560px){
    .container,
    .modal1 .divcenter{
        max-width:1500px;
    }
}

@media(max-width:1559px){
    
    #seccion-historia {
        .linea-hist {
            top: 479.6px;
        } 
        #historia-slider {
            .owl-nav [class*="owl-"] {
                top: 485px;
            }
        }
    }
    
    #modal-prensa {
        .col-texto > div {
            column-gap: 50px;   
        }
        .divcenter {
            padding: 90px;
        }
    }
}

@media(max-width:1359px){
    
    #seccion-historia {
        .linea-hist {
            top: 433px;
            width: calc(100% - 60px);
            left: 30px;
        }  
        #historia-slider {
            .cont-historia {
                padding: 20px 30px;
            }
            .circulo {
                left: 29px;
            }
            .owl-nav [class*="owl-"] {
                top: 440px;
                span {
                    display: none;
                }
            }
        }
    }
    
    .modal-vinos {
        .con-padding {
            padding: 0 80px;
        } 
        .con-fondo {
            padding: 40px 200px 10px 80px;
            .botella {
                max-width: 180px;
                bottom: -13px;
                right: 0px;
            }
        }  
        
        .cont-premios {
            right: -450px;
        }
    } 
    
    #modal-valles .col-izq > div img {
        max-width: 340px;
    }
    
    #modal-valles.modal-bodega {
        .foto-1 img,
        .foto-2 img {
            max-width: 320px;
        }
        
        .vinificacion,
        .embotellado {
            padding: 100px 15px;
        }
    }
}

@media(max-width:1199px){
    
    #seccion-historia {
        .linea-hist {
            top: 471px;
        }  
        #historia-slider {
            .owl-nav [class*="owl-"] {
                top: 477px;
            }
        }
    } 
    
    #seccion-sustentabilidad {
        #slider {
            max-width: 900px;
            bottom: -150px;
        }   
        .col1 > div {
            max-width: 320px;
            margin-right: 80px;
        }
    } 
    
    #seccion-texto {
        padding: 190px 0 60px;
    }
    
    #modal-prensa {
        .cont-titulo {
            padding-left: 40px;
        }
        .col-texto > div {
            column-gap: 25px;   
            padding: 40px;
        }
        .divcenter {
            padding: 70px 50px;
        }
        .titulo-modal {
            font-size: 25px;
        }
    }
    .modal-vinos {
        .divcenter {
            padding: 100px 160px 100px 0;
        }
        .con-fondo {
            padding: 40px 200px 10px 80px;
            .botella {
                max-width: 160px;
                right: 20px;
            }
        }
        .cont-premios {
            right: -320px;
        }
        .container-ficha {
            max-width: 75%;
        }
    }
    
    #seccion-sustentabilidad #sustentabilidad-slider .portfolio-overlay.nueva-seccion{
    h2:first-child {margin-top:15px;}
    h2 {font-size:16px;margin-bottom:6px;}
    a {margin-bottom:10px;font-size:10px;}
    
}
    
    
    
    
}

@media (max-width:991px){
    body #header {
        &.transparent-header, 
        &.semi-transparent {
            background-color: $gris-claro;
            border: 0;
        }
        #logo img {
            max-width: 140px;
            height: auto;
            margin-top: 18px;
        }
    }
    
    #icl_lang_sel_widget-2 {
        display: none;
    }
    .overlay-menu:not(.top-search-open) #primary-menu-trigger {
        line-height: 50px;
        height: 50px;
    }
    
    .overlay-menu {
        #primary-menu {
            > ul {
                > li {
                    
                    &.flotante {
                        display: none;
                    }
                    > a {
                        font-size: 14px;
                    }
                    &:nth-child(2){
                        border-top: 0;
                    }
                    &#logo-overlay{
                        display: none;
                    }
                    
                    #icl_lang_sel_widget-2 {
                        display: block !important;
                        .wpml-ls > ul {
                            display: block !important;
                            padding: 0;
                            border-top: 0;
                            ul {
                                padding: 0;
                            }
                        }
                        a {
                            span {
                                display: block;
                                color: $dorado;
                            }
                            font-family: $cormorant;
                            text-transform: uppercase;
                            font-weight: normal;
                            font-size: 14px;
                            letter-spacing: 1px;
                        }
                    }
                }
            }
        }
    }
    
    .banner-ppal .slider-caption img {
        max-width: 220px;
    }
    
    .bajada-cursiva {
        font-size: 23px;
        line-height: 35px !important;
    }
    
    .titulo-ppal {
        font-size: 28px;
    }
    
    .titulo-item {
        font-size: 15px;
    }
    
    #seccion-division {
        height: 360px;
    }
    
    #seccion-historia {  
        #historia-slider .owl-nav [class*="owl-"] {
            top: 517px;
        }
        .linea-hist {
            top: 512px;
        }
    } 
    
    #seccion-enologia {
        .titulo-ppal {
            margin-bottom: 100px;
        }
        #slider {
            max-width: 600px;
            margin: 0 auto;
        }
    }
    
    #seccion-vinos {
        #vinos-slider {
            .recuadro {
                width: 190px;
                margin-left: -95px;
            }    
        } 
    }  
    
    #seccion-sustentabilidad .col1 > div {
        max-width: 290px;
        margin-right: auto;
        margin-left: auto;
        float: none;
    }
    
    #seccion-sustentabilidad {
        #slider {
            max-width: 600px;
        } 
        #sustentabilidad-slider {
            padding-bottom: 30px;
        }
    } 
    
    #modal-valles {
        .col-izq {
            padding: 0 30px;
            > div {
                padding-left: 0px;
                img {
                    max-width: 100%;
                }
            }
            .lista-cursiva li {
                font-size: 19px;
                i {
                    font-size: 12px;
                    width: 18px;
                    height: 18px;
                    line-height: 18px;
                }
            }
        }
        .col-der {
            padding: 0 30px;
            .texto-cursivo {
                font-size: 19px;
                line-height: 27px !important;
            }
        }
    }
    
    #modal-prensa {
        .divcenter {
            padding: 70px;
        }
        .col-img {
            height: 300px;
        }
        .titulo-modal {
            font-size: 23px;
        }
    }
    
    .modal-vinos {
        .divcenter{
            padding: 50px 80px 50px 0;
        }
        .cont-tab {
            margin-bottom: 30px;
            padding: 0 80px;
        }
        #tab-variedades {
            display: block;
        }
        .list-group .list-group-item {
            display: inline-block;
            float: left;
            margin-right: 30px;
            &:before {
                content: "|";
                position: absolute;
                display: block;
                left: -17px;
                top: 5px;
            }
            &:first-child:before {
                display: none;
            }
        }
        .con-fondo {
            padding-bottom: 40px;
            .botella {
                bottom: 40px;
            }
        }
        .container-ficha {
            max-width: 80%;
        }
        .cont-premios {
            position: relative;
            bottom: 0;
            right: 0;
            margin-bottom: 30px;
            padding-left: 0;
            .titulo-premios {
                display: none;
            }
            .visitar{
                    display: block;
                    float: left;
                    margin-top: 40px !important;
                }
            .premios {
                margin-bottom: 20px;
                max-width: 80%;
                li {
                    display: inline-block;
                    width: 33%;
                    padding-right: 20px;
                    float: left;
                }
            }
        }
    }
    
    #modal-valles.modal-bodega {
        .foto-1 img,
        .foto-2 img {
            max-width: 290px;
        }
        
        .foto-1 {
            margin-bottom: 60px;
        }
        
        .vinificacion,
        .embotellado {
            padding: 80px 15px;
        }
    }
    
    footer {
        .tleft,
        .tright {
            text-align: center !important;
        }
    }
}

@media(max-width:767px){
    
    .bajada-cursiva {
        font-size: 21px;
        line-height: 33px !important;
    }
    
    .titulo-ppal {
        font-size: 25px;
        line-height: 29px;
    }
    
    #primary-menu-trigger span {
        display: none;
    }
    
    body .swiper_wrapper:not(.force-full-screen), 
    body .swiper_wrapper:not(.force-full-screen):not(.canvas-slider-grid) .swiper-slide,
    {
        height: 600px !important;
    }
    
    .banner-ppal .slider-caption img {
        max-width: 160px;
    }
    
    #seccion-division {
        height: 290px;
    }
    
    #seccion-historia {
        #slider {
            max-width: 340px;
            margin: 0 auto;
        }
        .linea-hist {
            top: 507px;
        }
        #historia-slider .owl-nav [class*="owl-"] {
            top: 514px;
        }
    }
    
    #seccion-valles #valles-slider .portfolio-overlay h2 {
        font-size: 17px;
    }
    
    #seccion-enologia {
        #slider {
            max-width: 280px;
            margin: 0 auto;
        }
        #enologia-slider .portfolio-overlay h1 {
            font-size: 32px;
            line-height: 38px;
        }
    } 
    
    #seccion-sustentabilidad {
        #slider {
            max-width: 500px;
            bottom: -120px;
        }
        .col1 > div {
            padding: 100px 0 70px;
            max-width: 340px;
        }
        .col2 {
            height: 300px !important;
        }
        #sustentabilidad-slider .portfolio-overlay h2 {
            font-size: 22px;
            line-height: 26px;
        }
    } 
    
    footer#footer ul li {
        display: block;
    }
    
    #modal-valles {
        .col-izq,
        .col-der{
            padding: 0 15px;
        }
    }
    
    .modal-vinos {
        .con-padding {
            padding-right: 15px;
            text-align: center;
        }
        .titulo-vino {
            margin-bottom: 30px;
        }
        .titulo-item {
            text-align: left;
        }
        .superior-derecha {
            padding-bottom: 0;
        }
        .descargar {
            position: relative;
            top: -54px;
            margin-bottom: -50px;
            padding-bottom: 0;
        }
        .con-fondo {
            padding: 40px 180px 10px 80px;
            .botella {
                bottom: auto;
                top: 40px;
                max-width: 130px;
            }
        } 
    }
    
    #modal-valles.modal-bodega {
        .foto-1 img, 
        .foto-2 img {
            max-width: 100%;
        }
        .foto-1 {
            margin-bottom: 0px;
        }
        
        .vinificacion,
        .embotellado {
            padding: 40px 15px;
        }
    }
    
    #seccion-sustentabilidad #sustentabilidad-slider .portfolio-overlay.nueva-seccion{
    h2:first-child {margin-top:4px;}
    h2 {font-size:14px;margin-bottom:6px;}
    a {margin-bottom:10px;font-size:10px;}
    }
}

@media(max-width:575px){

     #seccion-texto {
        padding: 130px 0 60px;
    } 
    body p{
        font-size: 15px;
        line-height: 23px !important;
    }
    .bajada-cursiva {
        font-size: 19px;
        line-height: 30px !important;
    }
    .titulo-ppal {
        font-size: 21px;
        line-height: 27px;
    }
    .btn-vino {
        padding: 7px 10px;
    }
    #seccion-division {
        height: 240px;
    }
    #seccion-historia {
        .linea-hist {
            top: 437px;
        }
        #historia-slider .owl-nav [class*="owl-"] {
            top: 443px;
        }
    }
    
    #seccion-valles {
        .bajada-cursiva {
            margin-bottom: 60px;
        }
        #valles-slider {
            margin-top: 90px !important;
        }
    } 
    
    #seccion-enologia {
        .section {
            padding-top: 180px;
        }
        #slider {
            bottom: -200px;
        }  
        #enologia-slider .portfolio-overlay {
            padding: 30px;
        }
    }
    
    #seccion-sustentabilidad {
        .titulo-ppal{
            margin-bottom: 30px;
        } 
        .col1 > div {
            max-width: 260px;
            padding: 80px 0 50px;
        }  
        #slider {
            max-width: 240px;    
        }
        
    } 
    
    footer#footer {
        .container {
            width: 330px !important;
        }
        p {
            font-size: 13px;
        } 
    }
    
    #modal-prensa {
        .divcenter {
            padding: 40px;
        }
        .cont-titulo {
            padding-left: 15px;
        }
        .mini-titulo {
            font-size: 14px;
        }
        .titulo-modal {
            font-size: 17px;
            letter-spacing: 1px;
        }
        .col-texto > div {
            padding: 30px;
            column-count: 1;
            column-gap: 0;
        }
        .col-img {
            height: 200px;
        }
    } 
    
    .modal-vinos {
        .divcenter {
            padding: 40px 60px;
        }
        .con-padding {
            padding: 0 15px;
        }
        .titulo-vino {
            margin-bottom: 30px;
        }
        .titulo-item {
            text-align: center;
            margin-bottom: 10px;
        }
        .descargar {
            text-align: center;
            top: 0;
            margin-bottom: 20px;
            font-size: 13px;
        }
        
        #tab-variedades {
            text-align: center;
        }
        .list-group .list-group-item {
            float: none;
            &:last-child {
                margin-right: 0;
            }
        }
        .cont-tab {
            padding: 0 15px;

        }
        .con-fondo {
            padding:30px 40px;
            text-align: center;
            .botella {
                bottom: auto;
                top: 0px;
                max-width: 100px;
                position: relative;
                margin-bottom: 30px;
                right: auto;
            }
            
        } 
        #tab-interno .nav-item {
            margin: 0 auto 20px;;
        }
    }
    #seccion-sustentabilidad #sustentabilidad-slider .portfolio-overlay.nueva-seccion{
    h2:first-child {margin-top:4px;}
    h2 {font-size:15px;margin-bottom:6px;}
    a {margin-bottom:10px;font-size:9px!important;padding:2px;}
    }
}

@media (min-width:992px)and(max-height:860px) {
    #logo_overlay {
        max-width: 180px;
        margin-left: -90px;
    }
    .overlay-menu #primary-menu {
        > ul > li > a {
            padding-top: 13px;
            padding-bottom: 13px;
            font-size: 19px;
        }   
    }
}

@media (min-width:992px)and(max-height:760px) {
    #logo_overlay {
        max-width: 160px;
        margin-left: -80px;
    }
    .overlay-menu #primary-menu {
        > ul > li {
            > a {
                padding-top: 13px;
                padding-bottom: 13px;
                font-size: 18px;
                letter-spacing: 1.6px;
            }  
            &:first-child {
                margin-top: 180px !important;
            }
            &.flotante {
                bottom: -40px;
                position: relative;
            }
        } 
    }
}

@media (min-width:992px)and(max-height:660px) {
    #logo_overlay {
        top: 60px;
    }
    
    .overlay-menu #primary-menu {
        > ul > li { 
            &:first-child {
                margin-top: 160px !important;
            }
            &.flotante {
                margin-bottom: 100px;
            }
        } 
    }
}





