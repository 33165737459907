/*-----------------------------------------------------------------------------------
	shortcodes.scss
-----------------------------------------------------------------------------------*/


//@import "shortcodes/countdown.scss";
//@import "shortcodes/buttons.scss";
//@import "shortcodes/promo.scss";
//@import "shortcodes/feature-box.scss";
//@import "shortcodes/process-steps.scss";
//@import "shortcodes/alerts.scss";
//@import "shortcodes/styled-icons.scss";
//@import "shortcodes/social-icons.scss";
@import "shortcodes/toggles-accordions.scss";
@import "shortcodes/tabs.scss";
//@import "shortcodes/faqs.scss";
//@import "shortcodes/clients.scss";
//@import "shortcodes/testimonials.scss";
//@import "shortcodes/team.scss";
//@import "shortcodes/pricing.scss";
//@import "shortcodes/counter-skills.scss";
@import "shortcodes/carousel.scss";
@import "shortcodes/flipcard.scss";
@import "shortcodes/overlay.scss";
@import "shortcodes/headings.scss";
//@import "shortcodes/dividers.scss";
//@import "shortcodes/youtubebg.scss";
@import "shortcodes/misc.scss";

