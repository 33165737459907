/* Globales */

$texto : #e8e8e8;
$gris-claro : #2b2b2b;
$gris-oscuro : #1e1e1e;
$dorado : #b58c50;
$gris : #8f8f8f;

$montserrat : 'Montserrat',
sans-serif;
$cormorant : 'Cormorant',
serif;

body {
    font-family: $montserrat;

    p {
        color: $texto;
        font-family: $cormorant;
        font-size: 17px;
        line-height: 25px !important;
    }
}

.back-oscuro {
    background-color: $gris-oscuro !important;
}

.back-negro {
    background-color: #000;
}

.back-claro {
    background-color: $gris-claro !important;
}

.back-full {
    background-position: center;
    background-size: cover;
    background-repeat: no-repeat;
}

.bajada-cursiva {
    font-family: $cormorant;
    font-style: italic;
    font-size: 25px;
    line-height: 37px !important;
    font-weight: lighter;
}

.puntos {
    font-size: 15px;
    font-weight: bold;
    color: $dorado;
    letter-spacing: 5px;
}

.section {
    padding: 80px 0;
}

.titulo-ppal {
    font-family: $cormorant;
    color: $dorado;
    text-transform: uppercase;
    font-weight: 400;
    font-size: 30px;
    letter-spacing: 3px;
    line-height: 34px;
}

.titulo-item {
    font-family: $montserrat;
    font-size: 16px;
    letter-spacing: 1.6px;
    color: $dorado;
    font-weight: lighter;
    text-transform: uppercase;
}

.owl-carousel .owl-dots .owl-dot {
    background-color: transparent;
    border: 0;
    padding: 0 2px;

    span {
        border-color: $gris;
        width: 7px;
        height: 7px;
    }

    &.active span {
        background-color: $gris !important;
    }
}

.detalle-inferior {
    font-size: 20px;
    line-height: 0;
    color: $texto;

    .linea {
        width: 20px;
        height: 1px;
        background-color: $texto;
        display: inline-block;
        position: relative;
        top: -1px;
        margin: 0 4px;
    }
}

.btn-vino {
    font-family: $cormorant;
    color: $texto !important;
    font-size: 17px;
    font-weight: lighter;
    letter-spacing: 0.2px;
    padding: 7px 27px;
    border: 1px solid $texto;
    cursor: pointer;

    &:hover {
        background-color: $dorado;
        border-color: $dorado;
        color: $gris-oscuro;
    }
}

button.mfp-close {
    width: 60px;
    height: 60px;
    line-height: 60px;
    font-size: 42px;
    opacity: 1;
}

.mfp-container {
    padding: 0 !important;
}

/* Menú */

.overlay-menu {
    #primary-menu {
        ul {
            transition: none !important;

            li {
                a {
                    font-family: $cormorant;
                    color: $dorado;
                    text-transform: uppercase;
                    font-weight: normal;
                    font-size: 20px;
                }

                &.flotante {
                    max-width: none;
                    position: absolute;
                    margin: 0 auto;
                    left: 0;
                    right: 0;
                    bottom: 30px;

                    p,
                    a,
                    span {
                        font-family: $cormorant;
                        font-size: 14px;
                        margin-bottom: 0;
                        color: $gris;
                        transition: opacity 0.7s 0.15s ease, transform 0.45s 0.15s ease, color 0.2s linear, -webkit-transform 0.45s 0.15s ease;
                        opacity: 0;
                        text-transform: none;

                    }

                    .inline {
                        display: inline;
                        margin: 0 5px;

                        i {
                            color: $dorado;
                            margin-right: 7px;
                            position: relative;
                            top: 3px;
                        }
                    }
                }

                &#logo-overlay {
                    max-width: 220px;
                    margin-top: 50px !important;
                    margin-bottom: 50px;
                }
            }

            &.d-block li.flotante {

                p,
                a,
                span {
                    opacity: 1;
                    transform: translateY(0);
                }
            }
        }
    }
}



.overlay-menu:not(.top-search-open) {
    #primary-menu-trigger {
        margin-top: 0;
        height: 100px;
        line-height: 100px;
        right: auto;
        left: 15px;
        color: #FFF;
        font-family: $montserrat;
        font-size: 13px;
        letter-spacing: 1px;
        font-weight: lighter;

        i {
            display: inline;
            margin-right: 10px;
        }
    }

    .wpml-ls-legacy-dropdown {
        width: auto;

        a {
            background-color: transparent !important;
            border: 0;
            color: #FFF !important;
            font-family: $montserrat;
            font-size: 12px;
            letter-spacing: 1px;
            font-weight: lighter;
            text-transform: uppercase;

        }

        .wpml-ls-sub-menu {
            border-top: 0;
            padding: 5px 0;

            a {
                padding: 10px;
            }
        }
    }
}


/* Banner */

.banner-ppal {
    .slider-caption {
        img {
            max-width: 290px;
        }
    }

    .one-page-arrow {
        height: auto;
        bottom: 0;
        width: auto;
        text-align: center;
        margin-left: -38px;

        .texto {
            font-size: 10px;
            color: $texto;
            display: block;
            margin-bottom: 10px;
            letter-spacing: 2.5px;
            text-transform: uppercase;
        }

        .linea {
            width: 1px;
            height: 80px;
            background-color: $texto;
            margin: 0 auto;
            display: block;
        }
    }
}

/* Intro */

#seccion-intro,
#seccion-valles,
#seccion-vinos,
#seccion-texto,
#seccion-enologia .section,
#seccion-bodega {
    .bajada-cursiva {
        max-width: 580px;
        margin: 0 auto 50px;
    }
}

#seccion-division {
    height: 400px;
}

#seccion-historia {
    .linea-hist {
        width: calc(100% - 100px);
        height: 2px;
        background-color: $gris;
        position: absolute;
        z-index: 3;
        top: 538px;
        left: 50px;
        opacity: 0.6;
    }

    #historia-slider {
        .cont-historia {
            padding: 20px 50px;
            text-align: left;
            position: relative;

            &:before {
                content: "";
                width: 0;
                height: 0;
                border-right: 15px solid transparent;
                border-top: 15px solid transparent;
                border-left: 15px solid transparent;
                border-bottom: 15px solid $gris-oscuro;
                display: block;
                position: absolute;
                top: -29px;
            }
        }

        .anio {
            font-family: $cormorant;
            color: $dorado;
            font-weight: lighter;
            margin-bottom: 60px;
        }

        .circulo {
            width: 7px;
            height: 7px;
            background-color: $gris;
            border-radius: 50%;
            position: absolute;
            top: 87px;
            left: 49px;
        }

        .titulo-item {
            color: $texto;
            margin-bottom: 5px;
        }

        .owl-nav [class*="owl-"] {
            top: 543px;
        }
    }
}

#seccion-historia,
#seccion-vinos {
    .owl-nav [class*="owl-"] {
        opacity: 1;
        background-color: transparent !important;
        padding: 0;
        width: auto;
        font-family: $montserrat;
        font-size: 10px;
        text-transform: uppercase;
        color: $gris !important;
        letter-spacing: 1px;

        i {
            font-size: 17px;
            color: $gris;
            display: inline;
            position: relative;
            top: 3px;
        }
    }

    .owl-nav .owl-next {
        text-align: right;

        i {
            margin-left: 2px;
        }
    }

    .owl-nav .owl-prev {
        text-align: left;

        i {
            margin-right: 2px;
        }
    }
}

#seccion-historia .owl-nav [class*="owl-"] i {
    font-size: 34px;
}

#seccion-valles {
    .bajada-cursiva {
        margin-bottom: 80px;
    }

    .descubre {
        background-color: $dorado;
        color: $gris-oscuro !important;
        font-family: $cormorant;
        font-size: 17px;
        padding: 8px 12px;
    }

    #valles-slider {
        margin-top: 100px !important;

        .owl-stage {
            padding: 0;
        }

        .portfolio-overlay {
            opacity: 1 !important;
            display: flex;
            justify-content: center;
            flex-direction: column;
            color: $texto;
            background-color: transparent;

            h2 {
                font-family: $montserrat;
                color: $texto;
                font-weight: lighter;
                font-size: 18px;
                text-transform: uppercase;
                letter-spacing: 2px;
            }
        }
    }
}

#seccion-enologia {
    overflow: visible;

    .contenedor-central {
        max-width: 1300px;
        margin: 0 auto;
    }

    .titulo-ppal {
        margin-bottom: 140px;
        padding-left: 60px;
    }

    #slider {
        bottom: -300px;
    }

    #enologia-slider {
        padding-bottom: 30px;

        .oc-item:hover .portfolio-overlay {
            opacity: 1;
        }

        .portfolio-overlay {
            background-color: $dorado;
            padding: 0 40px;
            text-align: center;
            display: flex;
            justify-content: center;
            flex-direction: column;
            opacity: 1;

            .descargar {
                background-color: rgba(0, 0, 0, 0.1);
                font-family: $montserrat;
                color: $texto;
                font-size: 11px;
                letter-spacing: 1.4px;
                text-align: center;
                border-radius: 0;
                width: 100px !important;
                height: auto !important;
                line-height: 20px;
                padding: 5px;
                margin: 0 auto 20px;
                position: relative;
                top: 0px;
                left: unset;
                display: block;
            }

            h2 {
                font-family: $cormorant;
                color: $texto;
                font-weight: lighter;
                font-size: 16px;
                letter-spacing: 0.2px;
                margin-bottom: 10px;
            }

            h1 {
                font-family: $cormorant;
                color: $texto;
                font-weight: lighter;
                font-size: 34px;
                line-height: 39px;
            }
        }
    }

    .section {
        padding-top: 260px;
        text-align: center;
    }
}

#seccion-vinos {
    .titulo-ppal {
        margin-bottom: 60px;
    }

    #vinos-slider {
        text-align: center;
        max-width: 1300px;
        margin: 0 auto;
        padding-bottom: 80px;

        .recuadro {
            width: 215px;
            height: 355px;
            position: absolute;
            left: 50%;
            top: 44px;
            margin-left: -107px;
        }

        img {
            max-width: 130px;
            margin: 0 auto 30px;
        }

        .tipo-vino {
            font-family: $cormorant;
            color: $texto;
            font-size: 17px;
            font-weight: lighter;
            letter-spacing: 0.2px;
        }

        .owl-nav [class*="owl-"] {
            top: auto;
            bottom: 0;

            i {
                top: 3px;
            }
        }

        .owl-nav .owl-next i {
            margin-left: 8px;
        }

        .owl-nav .owl-prev i {
            margin-right: 8px;
        }
    }
}

#seccion-sustentabilidad {
    overflow: visible;

    .col1>div {
        padding: 120px 0 240px;
        max-width: 440px;
        float: right;
        margin-right: 100px;
    }

    .titulo-ppal {
        margin-bottom: 70px;
    }

    #slider {
        max-width: 1000px;
        margin: 0 auto;
        position: absolute;
        bottom: -180px;
        left: 0;
        right: 0;
    }

    #sustentabilidad-slider {
        .portfolio-overlay {
            opacity: 1 !important;
            padding: 30px;
            display: flex;
            justify-content: center;
            flex-direction: column;
            background-color: transparent;

            h2 {
                font-family: $cormorant;
                font-weight: lighter;
                color: $texto;
                font-size: 25px;
                line-height: 29px;
                margin-bottom: 20px;
            }

            .detalle-inferior {
                margin-bottom: 30px;
            }

            .descargar {
                background-color: rgba(255, 255, 255, 0.3);
                font-family: $montserrat;
                color: $texto;
                font-size: 11px;
                letter-spacing: 1.4px;
                text-align: center;
                border-radius: 0;
                width: 100px !important;
                height: auto !important;
                line-height: 20px;
                padding: 5px;
                margin: 0 auto;
                position: relative;
                top: 0px;
                left: unset;
                display: block;
            }
        }
    }
}

#seccion-texto {
    padding: 210px 0 60px;
}

#seccion-prensa {
    #slider {
        max-width: 1000px;
        margin: 0 auto;
    }

    #prensa-slider {
        img {
            margin-bottom: 10px;
        }

        .fecha {
            margin-bottom: 5px;
            color: $gris;
        }

        .categoria {
            margin-bottom: 3px;
        }
    }
}

footer#footer {
    background-color: $gris-oscuro;
    text-align: center;
    border: 0;
    padding: 30px 0;

    p,
    a,
    li {
        font-family: $cormorant;
        font-size: 14px;
        margin-bottom: 0;
        color: $gris;
    }

    ul {
        margin-bottom: 0;

        li {
            display: inline;
            margin: 0 10px;

            i {
                margin-right: 7px;
                position: relative;
                top: 3px;
            }

            &:first-child {
                margin-left: 0;
            }
        }
    }
}

.modal1 {
    .divcenter {
        width: 100vw;
        min-height: 100vh;
        background-color: $gris-oscuro;
    }
}

.mfp-fade.mfp-bg.mfp-ready {
    background-color: $gris-oscuro;
    opacity: 1 !important;
}

#modal-prensa {
    .divcenter {
        padding: 90px 120px;
    }

    .cont-titulo {
        padding-left: 80px;
    }

    .col-texto {
        padding: 0;

        >div {
            column-count: 2;
            column-gap: 80px;
            background-color: $texto;
            padding: 60px;

            p {
                color: $gris;

                &:last-child {
                    margin-bottom: 0;
                }
            }
        }
    }

    .mini-titulo {
        font-family: $cormorant;
        color: $texto;
        margin-bottom: 0;
        text-transform: uppercase;
        font-size: 17px;
        font-weight: lighter;
        letter-spacing: 2.5px;
    }

    .titulo-modal {
        font-family: $montserrat;
        color: $dorado;
        letter-spacing: 2px;
        font-weight: lighter;
        font-size: 27px;
        text-transform: uppercase;
        max-width: 380px;
    }
}

#modal-valles {
    .divcenter {
        padding: 70px 0px;
    }

    .col-izq {
        >div {
            //padding-left: 40px;
            margin-bottom: 40px;

            img {
                max-width: 420px;
            }
        }

        .titulo-ppal {
            max-width: 220px;
        }

        .lista-cursiva {
            li {
                list-style: none;
                font-family: $cormorant;
                font-style: italic;
                color: $gris;
                font-size: 20px;
                margin-bottom: 20px;

                i {
                    color: $gris-oscuro;
                    background-color: $dorado;
                    width: 20px;
                    height: 20px;
                    line-height: 20px;
                    text-align: center;
                    border-radius: 50%;
                    margin-right: 20px;
                    font-size: 14px;
                }
            }
        }
    }

    .col-der {
        .texto-cursivo {
            font-family: $cormorant;
            font-style: italic;
            color: $gris;
            font-size: 20px;
            line-height: 28px !important;
        }

        .lista-num {
            li {
                list-style: none;

                h3 {
                    font-family: $montserrat;
                    color: $dorado;
                    font-weight: lighter;
                    letter-spacing: 1.6px;
                    font-size: 14px;
                    text-transform: uppercase;
                    margin-bottom: 10px;

                    span {
                        font-weight: bold;
                        color: $gris-oscuro;
                        background-color: $dorado;
                        width: 15px;
                        height: 15px;
                        border-radius: 50%;
                        text-align: center;
                        line-height: 15px;
                        font-size: 13px;
                        display: inline-block;
                        margin-right: 10px;
                        position: relative;
                        top: -1px;
                    }
                }

                p {
                    color: $gris;
                    font-size: 16px;
                    line-height: 23px !important;
                    max-width: 500px;
                    padding-left: 25px;
                    margin-bottom: 15px;
                }
            }
        }
    }

    &.modal-bodega {
        .divcenter {
            padding: 80px 0;
        }

        .foto-1 img,
        .foto-2 img {
            max-width: 400px;
        }

        .foto-1 {
            margin-bottom: 90px;
        }

        .vinificacion,
        .embotellado {
            padding: 140px 15px;
        }
    }
}

.modal-enologia {
    .divcenter {
        padding: 0px 120px;
    }

    .cont-titulo {
        min-height: 100vh;
        overflow: auto;
    }

    .vertical {
        position: absolute;
        top: 50%;
    }

    .nombre-enologo {
        font-family: $cormorant;
        color: $dorado;
        font-weight: lighter;
        font-size: 34px;
        line-height: 39px;
        margin-bottom: 15px;
    }

    .cargo {
        font-family: $cormorant;
        font-weight: 400;
        color: $texto;
        font-size: 20px;
        margin-bottom: 40px;
    }
}


.modal-vinos {
    .divcenter {
        padding: 100px 160px 100px 0;
    }

    .con-padding {
        padding: 0 130px;
    }

    .titulo-vino {
        max-width: 160px;
        margin-bottom: 15px;
    }

    .titulo-item {
        color: $texto;
    }

    .superior-derecha {
        display: flex;
        justify-content: flex-end;
        flex-direction: column;
        padding-bottom: 30px;
    }

    .descargar {
        font-family: $cormorant;
        color: $dorado !important;
        text-transform: uppercase;
        display: block;
        text-align: right;
        font-size: 15px;
        letter-spacing: 3px;

        i {
            position: relative;
            top: 2px;
        }
    }

    .list-group {
        .list-group-item {
            width: auto;
            display: block;
            text-align: right;
            background-color: transparent;
            border: 0;
            font-family: $cormorant;
            color: $gris;
            font-size: 18px;
            letter-spacing: 2px;
            position: relative;
            padding: 5px 0;

            &.active {
                background-color: transparent;
                border: 0;
                color: $texto;
            }

            &:last-child {
                padding-right: 0;

                &:after {
                    display: none;
                }
            }
        }
    }

    .con-fondo {
        background-color: $texto;
        padding: 40px 260px 10px 130px;

        .titulo-ppal {
            font-size: 26px;
            margin-bottom: 5px;
        }

        .botella {
            max-width: 200px;
            position: absolute;
            bottom: -26px;
            right: 30px;
        }

        .titulo-ppal {
            font-size: 17px;
            letter-spacing: 0.2px;
            margin-bottom: 0;
            text-transform: capitalize;

            &.titulo-variedad {
                font-size: 20px;
                margin-bottom: 30px;
                text-transform: uppercase;
                font-weight: 500;
            }
        }

        ul {
            list-style: none;

            li {
                font-family: "Cormorant", serif;
                margin-bottom: 3px;
            }
        }

        p,
        li {
            color: $gris;
            font-size: 15.5px;
            line-height: 23px !important;
        }

        .cursivo {
            font-style: italic;
            font-size: 18px;
            line-height: 25px !important;
        }
    }

    #tab-interno {
        .show>.nav-link {
            background-color: transparent;
        }

        a {
            font-family: $cormorant;
            color: $dorado;
            font-size: 13px;
            letter-spacing: 0px;
        }

        .nav-link {
            padding: 10px 0;
            margin-bottom: 10px;

            &.active {
                background-color: transparent;
            }
        }

        ul {
            background-color: $texto;

            a {
                color: $gris;
                font-size: 16px;

                &.active {
                    background-color: transparent;
                    color: $dorado;
                }

                &:hover {
                    background-color: transparent;
                }
            }
        }
    }

    #tab-variedades {
        margin-bottom: 0;

        ul.tab-nav.tab-nav2 {
            li {
                margin-left: 0;
                position: relative;

                a {
                    background-color: transparent;
                    font-family: $cormorant;
                    text-transform: uppercase;
                    color: $gris;
                    font-weight: lighter;
                    box-shadow: none;
                    font-size: 15px;
                    letter-spacing: 2.5px;
                    border-radius: 0;
                    padding: 0 30px;
                }

                &.ui-state-active a {
                    color: $gris-oscuro;
                }

                &:after {
                    content: "";
                    width: 1px;
                    height: 16px;
                    background-color: $gris;
                    position: absolute;
                    right: 0;
                    top: 12px;
                    display: block;
                }

                &:first-child a {
                    padding-left: 0;
                }

                &:last-child:after {
                    display: none;
                }
            }
        }


    }

    .cont-premios {
        position: absolute;
        right: -500px;
        bottom: -14px;
        width: auto;

        .titulo-premios,
        .visitar {
            font-family: $cormorant;
            color: $dorado;
            text-transform: uppercase;
            font-size: 17px;
            font-weight: lighter;
            letter-spacing: 2px;
            margin-bottom: 20px;
        }

        .visitar {
            font-size: 14px;
            letter-spacing: 2px;

            span {
                font-size: 20px;
                margin-left: 11px;
                position: relative;
                top: 4px;
            }
        }

        .premios {
            li {
                list-style: none;
                margin-bottom: 15px;

                img {
                    display: inline-block;
                    max-width: 60px;
                    margin-right: 8px;
                }

                p {
                    display: inline-block;
                    font-size: 15px;
                    color: $gris;
                    line-height: 22px !important;
                    margin-bottom: 0;
                }
            }
        }
    }
}

#seccion-sustentabilidad #sustentabilidad-slider .portfolio-overlay.nueva-seccion {
    h2:first-child {
        margin-top: 15px;
    }

    h2 {
        font-size: 20px;
        margin-bottom: 8px;
    }

    a {
        margin-bottom: 20px;
    }

}


#seccion-contacto {
    .wpforms-form {
        max-width: 460px;
        margin: 0 auto;

        input,
        textarea,
        .choices__inner, select {
            background-color: transparent;
            border: 1px solid $dorado;
            border-radius: 0px;
            text-align: center;
            color: #fff;
            font-size: 13px;
        }

        ::placeholder,
        .choices__placeholder {
            color: $texto !important;
            font-size: 14px;
            opacity: 1;
            text-align: center;
        }

    }

    div.wpforms-container .wpforms-form .choices[data-type*="select-one"]:after {
        border-color: $texto transparent transparent transparent;
    }

    div.wpforms-container-full .wpforms-form label.wpforms-error {
        color: #fff !important;
        font-weight: normal;
        font-family: "Montserrat", sans-serif;
        font-size: 11px;
        margin-top: 4px
    }

    .wpforms-confirmation-container-full,
    div[submit-success]>.wpforms-confirmation-container-full:not(.wpforms-redirection-message) {
        background: transparent;
        border: 1px solid #fff !important;
        max-width: 660px;
    }

    .mailcheck-error {
        display: none !important
    }

    .wpforms-submit {
        background-color: rgba(255, 255, 255, 0.3);
        font-family: "Montserrat", sans-serif;
        color: #e8e8e8;
        font-size: 12px;
        letter-spacing: 1.4px;
        text-align: center;
        border-radius: 0;
        width: 100px !important;
        height: auto !important;
        line-height: 20px;
        padding: 5px;
        text-transform: uppercase;
        letter-spacing: 1px;
    }
    .wpforms-submit:hover {
        background: #1e1e1e
    }
    div.wpforms-container .wpforms-form .choices__list--dropdown .choices__item--selectable { padding-right: 0px ;}
    .choices__button { display: none}



}